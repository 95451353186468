import type { PageBlokInterface, StoryblokBlokInterface } from '@energysage/storyblok-shared';

export default class PageBlokClass implements PageBlokInterface {
    _uid: number;

    _editable: string;

    component: string;

    title: string;

    body: StoryblokBlokInterface[];

    seo: {
        _uid: number;

        plugin: string;

        title: string;

        description: string;

        og_title: string;

        og_image: string;

        og_description: string;

        twitter_image: string;

        twitter_title: string;

        twitter_description: string;
    };

    constructor() {
        /* eslint-disable no-underscore-dangle */
        this._uid = 0;
        this._editable = '';
        /* eslint-enable no-underscore-dangle */
        this.component = '';
        this.title = '';
        this.body = [];
        this.seo = {
            _uid: 0,
            title: '',
            plugin: '',
            description: '',
            og_title: '',
            og_image: '',
            og_description: '',
            twitter_image: '',
            twitter_title: '',
            twitter_description: '',
        };
    }
}
