
export default {
    // per https://v2.nuxt.com/docs/concepts/views/#error-page
    // "Although this file is placed in the layouts folder, it should be treated as a page."
    name: 'ErrorPage',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        errorCode() {
            return String(this.error.statusCode);
        },
    },
};
