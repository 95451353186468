import { renderRichText as storyBlokRenderRichText } from '@storyblok/vue-2';
import type { StoryblokRichTextInterface, StoryblokComponentInterface } from '~/types/storyblok';
import type { PageBlokInterface, PageStoryInterface } from '@energysage/storyblok-shared';

export type ImageDetails = {
    width: number;
    height: number;
    fileName: string;
    extension: string;
};
/**
 * Since empty rich text objects can still contain an array with an empty paragraph, this function
 * provides an easy way to check if there is actual text to render in a rich text, allowing a v-if
 * to not render a containing element (usually with a bottom margin) if it won't end up having any content.
 * @param richText A Storyblok rich text object.
 * @returns {boolean} True if the rich text object contains text, false if it doesn't.
 */
export const doesRichTextContainText = (richText: StoryblokRichTextInterface): boolean => {
    if (!richText) {
        return false;
    }

    if (richText.type === 'text' && richText.text) {
        return true;
    }

    if (richText.content && richText.content.length > 0) {
        return richText.content.reduce((result, item) => result || doesRichTextContainText(item), false);
    }

    return false;
};

/**
 * Assumes a URL string like 'https://a-us.storyblok.com/f/1006156/550x275/ecd486cb09/installer-badges.svg'.
 */
export const getStoryblokImageDetails = (storyblokImageUrl: string | undefined): ImageDetails => {
    const details: ImageDetails = {
        width: 0,
        height: 0,
        fileName: '',
        extension: '',
    };

    if (!storyblokImageUrl) return details;

    // Regex pattern to match width, height, and file name with extension
    const pattern = /\/(\d+)x(\d+)\/(.+?\..+?)$/;
    const match = storyblokImageUrl.match(pattern);

    if (match) {
        // Destructure the match array and convert width and height to numbers
        const [, width, height, fileName] = match;

        details.width = parseInt(width, 10);
        details.height = parseInt(height, 10);
        details.fileName = fileName.split('/').pop() || '';
        details.extension = details.fileName.split('.').pop() || '';
    }

    return details;
};

export const processDomainTokensInUrl = (url: string) =>
    url
        .replace('{COMMUNITY_SOLAR_DOMAIN}', process.env.COMMUNITY_SOLAR_DOMAIN || '')
        .replace('{ES_DOMAIN}', process.env.ES_DOMAIN || '')
        .replace('{HEAT_PUMPS_DOMAIN}', process.env.HEAT_PUMPS_DOMAIN || '');

export const renderRichText = (content: StoryblokRichTextInterface) => {
    if (!content) return '';

    return storyBlokRenderRichText(content, {
        resolver: (component: string, blok: any) => `<component :blok='${JSON.stringify(blok)}' is="${component}" />`,
    });
};

/* modified from es-cms-local-data utils/storyblok.ts */
export const findComponents = (
    data: PageBlokInterface | StoryblokComponentInterface[] | StoryblokComponentInterface | undefined,
    componentName: string,
): StoryblokComponentInterface[] => {
    let foundComponents: StoryblokComponentInterface[] = [];
    // Case 1: data is list of objects -> concatenate component lists for each
    if (Array.isArray(data)) {
        data.forEach((item) => {
            const results = findComponents(item, componentName);
            if (results.length > 0) {
                foundComponents = [...foundComponents, ...results];
            }
        });
        return foundComponents;
    }
    // Case 2: data is an object -> return found component, or recurse
    if (typeof data === 'object' && data !== null) {
        // Base case: this object is the component we're collecting
        if (data.component === componentName) {
            return [data as StoryblokComponentInterface];
        }
        // Recursive case: search for & aggregate components in each object property
        // Check for _uid to ensure this is a component and not a resolved relation to another story
        // eslint-disable-next-line no-underscore-dangle
        if (data._uid) {
            Object.values(data).forEach((value) => {
                const results = findComponents(value, componentName);
                if (results.length > 0) {
                    foundComponents = [...foundComponents, ...results]; // results BAD
                }
            });
            return foundComponents;
        }
        return [];
    }
    // Case 3: data is not an object
    return [];
};

/* Returns an array of components of type componentName */
export const findComponentsInPage = (
    response: PageStoryInterface,
    componentName: string,
): StoryblokComponentInterface[] => findComponents(response.content, componentName);

/* eslint-disable */
export const findStoryblokObjectsMatchingTest = (
    data:
        | PageBlokInterface
        | PageStoryInterface
        | StoryblokComponentInterface[]
        | StoryblokComponentInterface
        | undefined,
    test: Function,
    depth: number,
    maxDepth: number,
): any[] => {
    const matchingObjects: any[] = [];
    if (depth === maxDepth) {
        return matchingObjects;
    }
    // if an array, call this function on each item
    if (Array.isArray(data)) {
        return data.reduce(
            (result, item) => [...result, ...findStoryblokObjectsMatchingTest(item, test, depth + 1, maxDepth)],
            matchingObjects,
        );
    }
    if (typeof data === 'object' && data !== null) {
        // if the provided test passes for this object, return the object (in an array)
        if (test(data)) {
            return [data];
        }
        // call this function on each value within this object
        return Object.values(data).reduce(
            (result, item) => [...result, ...findStoryblokObjectsMatchingTest(item, test, depth + 1, maxDepth)],
            matchingObjects,
        );
    }
    return matchingObjects;
};
/* eslint-enable */

export const computedReplacement = (props: Object, field: string) => {
    let finalText = field;
    Object.entries(props).forEach(([key, value]) => {
        finalText = finalText.replaceAll(`{${key}}`, value);
    });
    return finalText;
};
