// Based on https://github.com/storyblok/storyblok-nuxt-2/blob/main/lib/module/plugin.mjs

import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import 'isomorphic-fetch';
import Vue from 'vue';

import { StoryblokVue, useStoryblokApi, useStoryblokBridge, apiPlugin } from '@storyblok/vue-2';
import { Route } from 'vue-router';
import { StoryblokResponseInterface } from '~/types/storyblok';
import type { PageStoryInterface } from '@energysage/storyblok-shared';
import { findStoryblokObjectsMatchingTest } from '~/utils/storyblok';
import StoryblokStoryClass from '~/classes/StoryblokStoryClass';

export default defineNuxtPlugin(({ $config }, inject) => {
    Vue.use(StoryblokVue, {
        accessToken: $config.STORYBLOK_ACCESS_TOKEN,
        bridge: true,
        apiOptions: {
            cache: {
                type: $config.NODE_ENV === 'development' ? 'none' : 'memory',
            },
            region: 'us',
        },
        use: [apiPlugin],
    });

    const api = useStoryblokApi();

    /**
     * Loads the story data from the given URL with the given relations, if any, resolved.
     * @param url The Storyblok story path to load, like 'cdn/stories/home'.
     * @param throwNuxtError The Nuxt error function provided to asyncData().
     * @param relationsToResolve An array of '{BlockName}.{fieldName}' strings.
     * @param route The route object from nuxt context
     * @returns The story data.
     */
    const loadPage = async (
        url: string,
        throwNuxtError: Function,
        relationsToResolve: string[] = [],
        route = <Route>{},
    ) => {
        const isPreview = route.query.preview;
        let story: PageStoryInterface = new StoryblokStoryClass();
        let response: StoryblokResponseInterface = {
            story: '',
            rels: [],
        };

        try {
            console.log(`[REQUEST]: ${url}`);
            const { data } = await api.get(url, {
                version: isPreview ? 'draft' : 'published',
                resolve_relations: relationsToResolve,
                resolve_links: 'url',
            });

            response = data;
        } catch (e: any) {
            // On an error from API present user nuxt error page
            // https://nuxtjs.org/docs/internals-glossary/context/#error
            throwNuxtError(e);
        }

        story = response.story;

        return story;
    };

    const fetchDataSource = async (datasource: string) => {
        let response;
        try {
            console.log(`[REQUEST]: 'cdn/datasource_entries' for ${datasource}`);
            const { data } = await api.get('cdn/datasource_entries', {
                datasource,
            });
            response = data;
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        // https://www.storyblok.com/docs/api/content-delivery/v2/datasources/retrieve-multiple-datasource-entries
        const datasourceEntries = response?.datasource_entries || [];
        const datasourceObject = datasourceEntries.reduce((obj: Record<string, any>, item: any) => {
            // Assuming each item has a unique 'name' property that we can use to form the keys of the new object
            // eslint-disable-next-line no-param-reassign
            obj[item.name] = item.value;
            return obj;
        }, {});

        return datasourceObject;
    };

    /**
     * resolve_links only goes one level deep, per Storyblok documentation
     * https://www.storyblok.com/docs/api/content-delivery/v2#core-resources/stories/retrieve-one-story
     *
     * so in the case where we use resolve_relations to load a story and that story contains an internal link we need
     * (for example a GlobalVerticalCta whose GlobalCtaLink has an internal link), that link will not be resolved and
     * we won't have the information needed to populate the 'to' or 'href' of the rendered link.
     *
     * this function finds those unresolved links, retrieves their stories, and modifies the given story data to put the
     * retrieved stories inside the link objects, just as they would be if resolve_links was able to go two levels deep.
     *
     * NOTE: to reduce complexity, this function mutates the provided story object.
     */
    const resolveSecondLevelLinks = async (story: PageStoryInterface, isPreview: boolean) => {
        // find link objects that are a story reference for which the story data was not resolved
        // set the max depth to approximate "two levels deep" so it will only find links needed
        // by this article, not by resolved/linked articles (which would be 3-4 levels deep)
        const unresolvedLinks = findStoryblokObjectsMatchingTest(
            story,
            (data: any) => data.linktype === 'story' && !data.story,
            0,
            9,
        );

        // gather (and dedupe) the ids of the stories we need to retrieve
        const ids = Object.keys(
            unresolvedLinks
                .filter((item) => item.id)
                .reduce((result, item) => {
                    // eslint-disable-next-line no-param-reassign
                    result[item.id] = true;
                    return result;
                }, {}),
        );

        // if we have no stories to retrieve, get out
        if (!ids.length) {
            return;
        }

        console.log(`[RESOLVE SECOND LEVEL LINKS] for ${story.full_slug}`);
        console.log(
            `[REQUEST]: 'cdn/stories?by_uuids=${ids.join(',')}&per_page=100&version=${
                isPreview ? 'draft' : 'published'
            }`,
        );
        const { data } = await api.get('cdn/stories', {
            by_uuids: ids.join(','),
            per_page: 100,
            version: isPreview ? 'draft' : 'published',
        });

        if (data?.stories?.length) {
            unresolvedLinks.forEach((link) => {
                data.stories.forEach((retrievedStory: PageStoryInterface) => {
                    if (link.id === retrievedStory.uuid) {
                        // eslint-disable-next-line no-param-reassign
                        link.story = retrievedStory;
                    }
                });
            });
        }
    };

    inject('storyblok', {
        api,
        useStoryblokBridge,
        loadPage,
        fetchDataSource,
        resolveSecondLevelLinks,
    });
});
