import type { PageBlokInterface, PageStoryInterface, StoryblokBlokInterface } from '@energysage/storyblok-shared';

import PageBlokClass from '~/classes/PageBlokClass';
import StoryblokBlokClass from '~/classes/StoryblokBlokClass';

export default class StoryblokStoryClass implements PageStoryInterface {
    id: number;

    uuid: string;

    name: string;

    content: PageBlokInterface;

    blok: StoryblokBlokInterface;

    first_published_at: string;

    published_at: string;

    created_at: string;

    constructor() {
        this.id = 0;
        this.uuid = '';
        this.name = '';
        this.content = new PageBlokClass();
        this.blok = new StoryblokBlokClass();
        this.first_published_at = '';
        this.published_at = '';
        this.created_at = '';
    }
}
