import type { StoryblokBlokInterface, StoryblokRichTextInterface } from '@energysage/storyblok-shared';

export default class StoryblokBlokClass implements StoryblokBlokInterface {
    _uid: string;

    _editable: string;

    component: string;

    HeaderText?: string;

    body: StoryblokBlokInterface[];

    richtext?: StoryblokRichTextInterface;

    // eslint-disable-next-line no-undef
    [x: string | number | symbol]: unknown;

    constructor() {
        /* eslint-disable no-underscore-dangle */
        this._uid = '';
        this._editable = '';
        /* eslint-enable no-underscore-dangle */
        this.component = '';
        this.HeaderText = '';
        this.body = [];
    }
}
